export const DEFAULT_LOCALE = 'en-US';
export const CUSTOM_MONTH_ABBRS = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'June',
  'July',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.'
];

export function fromUnixTimestamp(unixTime) {
  return new Date(unixTime * 1000);
}

export function fromRubyDateString(string) {
  const date = new Date(string);
  date.setUTCHours(10);
  return date;
}

/**
 * dateObj (UTC) -> '5:47 AM' (in user's timezone)
 */
export function getTimeString(date, timeZone) {
  return date.toLocaleString(DEFAULT_LOCALE, {
    hour: 'numeric',
    minute: '2-digit',
    ...(timeZone && {
      timeZone,
      timeZoneName: 'short'
    })
  });
}

/**
 * dateObj (UTC) -> 'Wednesday' (in user's timezone)
 */
export function getDayName(date) {
  return date.toLocaleString(DEFAULT_LOCALE, { weekday: 'long' });
}

export function getShortMonthName(date) {
  return CUSTOM_MONTH_ABBRS[date.getMonth()];
}

export function getLongMonthName(date) {
  return date.toLocaleString(DEFAULT_LOCALE, { month: 'long' });
}

/**
 * dateObj (UTC) -> '12/28/2018' (in user's timezone)
 */
export function getDateString(date) {
  return date.toLocaleDateString();
}

export function getShortDateString(date, omitYearIfCurrent = true) {
  const isThisYear = date.getFullYear() === new Date().getFullYear();
  const month = getShortMonthName(date);
  const day = date.toLocaleString(DEFAULT_LOCALE, { day: '2-digit' });
  const year = date.toLocaleString(DEFAULT_LOCALE, { year: 'numeric' });

  return `${month} ${day}${isThisYear && omitYearIfCurrent ? '' : `, ${year}`}`;
}

/*
 * dateObj (UTC) -> '12/28/2023' (in user's timezone)
 */
export function getLongNumericDate(date) {
  return date.toLocaleString(DEFAULT_LOCALE, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
}

export function getNumberOfDaysBetween(firstDate, secondDate) {
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const difference = Math.abs(firstDate - secondDate);

  return Math.round(difference / ONE_DAY);
}

/**
 * dateObj (UTC) -> '12/28' (in user's timezone)
 */
export function getShortNumericDate(date) {
  return date.toLocaleString(DEFAULT_LOCALE, {
    day: '2-digit',
    month: '2-digit'
  });
}

/**
 * dateObj (UTC) -> '2/3/23' (in user's timezone)
 */
export function getShortNumericDateAndYear(date) {
  return date.toLocaleString(DEFAULT_LOCALE, {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit'
  });
}

export function isToday(date) {
  return date.toDateString() === new Date().toDateString();
}

export function isThisWeek(date) {
  const beginningOfWeek = new Date();
  beginningOfWeek.setDate(beginningOfWeek.getDate() - beginningOfWeek.getDay());
  beginningOfWeek.setHours(0, 0, 0, 0); // sun at midnight

  const endOfWeek = new Date(beginningOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999); // sat at midnight

  return date >= beginningOfWeek && date <= endOfWeek;
}

export function getBeginningOfDate(date) {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function addDaysToDate(date, numDays) {
  date = new Date(date);
  date.setDate(date.getDate() + numDays);
  return date;
}

/**
 * =====================
 *  CUSTOM DATE FORMATS
 * =====================
 */

/**
 * when date is today -> 'Today'
 * when date is yesterday -> 'Yesterday'
 * when date is this week -> day name (ie 'Wednesday')
 * otherwise -> full date string (ie '12/10/2018')
 */
export function getDayOfWeekOrDateString(date) {
  if (!isThisWeek(date)) {
    return getDateString(date);
  }

  return (
    ['Today', 'Yesterday'][new Date().getDay() - date.getDay()] ||
    getDayName(date)
  );
}

/**
 * when date is today:
 *   - return time string (ie '11:43 AM') if withTime === true
 *   - return 'Today' if withTime === false
 * when date is not today -> short date string (ie 'Nov 23')
 */
export function getTimeTodayOrDateString(date, withTime = true) {
  if (isToday(date)) {
    if (withTime) {
      return getTimeString(date);
    }

    return 'Today';
  }

  return getShortDateString(date);
}

// HACK: Dumb regex until we need something better
const hoursRe = /([0-9]{1,2})\s*:\s*([0-9]{1,2})\s*(am|pm)/i;
/**
 * Transform a human readable time of day ('11:30 AM', '12:32pm')
 * into today's timestamp with the given offset
 */
export function displayHoursToTimestamp(hoursStr = '') {
  const matches = hoursStr.match(hoursRe);

  if (!matches) {
    return new Date().setHours(0, 0, 0, 0);
  }

  const [, hours, minutes, meridiem] = matches;
  const adjustedHours = Number(hours) % 12;
  return new Date().setHours(
    meridiem.toLowerCase() === 'pm' ? adjustedHours + 12 : adjustedHours,
    Number(minutes),
    0,
    0
  );
}

export function getMiddleOfMonthDate(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const midDate = new Date(year, month, 15);

  return midDate;
}

export function isValidDate(date) {
  return date instanceof Date && !Number.isNaN(date.getTime());
}

export function getUserTimeZone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to get user timezone:', e);
  }

  return null;
}

export function getFormattedDateTime(date, timeZone) {
  if (!date) {
    return null;
  }

  if (typeof date === 'string') {
    date = new Date(date);

    if (!isValidDate(date)) {
      return null;
    }
  }

  return `${getShortDateString(date)}, ${getTimeString(date, timeZone)}`;
}

/**
 * dateObj (UTC)      -> '12:00 PM'
 * dateObj (UTC), int -> '12:00 PM - 1:00 PM'
 */
export function getHourRange(startDate, step) {
  if (!isValidDate(startDate)) {
    return null;
  }

  const formattedStartDate = getTimeString(startDate);

  if (!step) {
    return formattedStartDate;
  }

  const endDate = new Date(startDate);
  endDate.setHours(endDate.getHours() + step);
  return `${formattedStartDate} - ${getTimeString(endDate)}`;
}

export function monthsBetweenDates(startDate, endDate) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}
