export function getLocalStorageValue(key, deserializeFn = JSON.parse) {
  try {
    return deserializeFn(window.localStorage.getItem(key));
  } catch (e) {
    window.console.error(`Could not fetch [${key}] from localStorage`, e);
  }

  return null;
}

export function setLocalStorageValue(key, value, serializeFn = JSON.stringify) {
  try {
    window.localStorage.setItem(key, serializeFn(value));
    return true;
  } catch (e) {
    window.console.error(`Could not set [${key}] in localStorage`, e);
  }

  return false;
}

export function removeLocalStorageValue(key) {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    window.console.error(`Could not remove [${key}] from localStorage`, e);
  }

  return false;
}
