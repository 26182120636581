export const EVENT_INTENDED_TO_RESCHEDULE = 'EVENT_INTENDED_TO_RESCHEDULE';
export const IMPERSONATE_BANNER_HIDDEN = 'IMPERSONATE_BANNER_HIDDEN';
export const OPEN_IN_APP_BANNER_DISMISSED_AT =
  'OPEN_IN_APP_BANNER_DISMISSED_AT';
export const BUYER_OPEN_IN_APP_BANNER_DISMISSED_AT =
  'BUYER_OPEN_IN_APP_BANNER_DISMISSED_AT';
export const PAYMENTS_SETUP_CALLOUT_KEY = 'PAYMENTS_SETUP_CALLOUT';
export const ANALYTICS_ORIGIN_KEY = 'ANALYTICS_ORIGIN_KEY';
export const PAYMENT_POLICY_BANNER_DISMISSED_AT =
  'PAYMENT_POLICY_BANNER_DISMISSED_AT';
export const QR_CODE_APP_STORE_BANNER_DISMISSED_AT =
  'QR_CODE_APP_STORE_BANNER_DISMISSED_AT';
export const MOBILE_WEB_APP_STORE_BANNER_DISMISSED_AT =
  'MOBILE_WEB_APP_STORE_BANNER_DISMISSED_AT';

export const PREFERRED_BREEDER_PROGRAM_MODULE_DISMISSED_AT =
  'PREFERRED_BREEDER_PROGRAM_MODULE_DISMISSED_AT';

export const LAST_VIEWED_TO_DO_PULSE_AT = 'LAST_VIEWED_TO_DO_PULSE_AT';
export const BREEDER_BUYER_APP_MARKETING_BANNER_DISMISSED =
  'BREEDER_BUYER_APP_MARKETING_BANNER_DISMISSED';
