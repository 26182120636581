/**
 * Recursively sort object based on its keys
 */
export function sort(obj, sortFn) {
  return Object.keys(obj)
    .sort(sortFn)
    .reduce((sortedObj, key) => {
      sortedObj[key] =
        typeof obj[key] === 'object' ? sort(obj[key], sortFn) : obj[key];
      return sortedObj;
    }, {});
}

/**
 * https://stackoverflow.com/a/5878101/3220101
 */
export function isPlain(obj) {
  if (typeof obj === 'object' && obj !== null) {
    if (typeof Object.getPrototypeOf === 'function') {
      const proto = Object.getPrototypeOf(obj);
      return proto === Object.prototype || proto === null;
    }
    return Object.prototype.toString.call(obj) === '[object Object]';
  }
  return false;
}

/**
 * Naive, but cheap, deep clone. Will SO if has circular reference
 */
export function deepClone(src) {
  return Object.entries(src).reduce(
    (dest, [key, value]) => {
      dest[key] =
        Array.isArray(value) || isPlain(value) ? deepClone(value) : value;
      return dest;
    },
    Array.isArray(src) ? [] : {}
  );
}

export function get(obj, path, valueIfMissing = undefined) {
  const keys = path.split('.');
  let key;
  let value = obj;

  while (keys.length > 0) {
    key = keys.shift();
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return valueIfMissing;
    }
  }

  return value;
}

export function exists(obj, path) {
  const testObj = {};
  return get(obj, path, testObj) !== testObj;
}

export function set(obj, path, value) {
  const clone = deepClone(obj);
  path.split('.').reduce((slice, key, idx, keys) => {
    const nextKey = keys[idx + 1];
    if (!nextKey) {
      // last key
      slice[key] = value;
    } else if (!slice[key]) {
      slice[key] = Number.isInteger(parseFloat(nextKey)) ? [] : {};
    }

    return slice[key];
  }, clone);

  return clone;
}

export function remove(obj, path) {
  const clone = deepClone(obj);
  path.split('.').reduce((slice, key, idx, keys) => {
    if (idx === keys.length - 1) {
      // last key
      if (Array.isArray(slice)) {
        slice.splice(key, 1);
      } else {
        delete slice[key];
      }
    }
    return slice[key];
  }, clone);

  return clone;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/**
 * Given multiple object paths, returns a reference to the first value found,
 * otherwise returns valueIfMissing
 */
export function coalesceGet(obj, paths, valueIfMissing = undefined) {
  if (!paths[0]) {
    return valueIfMissing;
  }

  const value = get(obj, paths[0]);

  if (value !== undefined) {
    return value;
  }

  return coalesceGet(obj, paths.slice(1), valueIfMissing);
}

/**
 * This function will take in an object and will remove any properties that are undefined or null
 * It supports nested objects as well
 * @param obj the object that you would like to strip all null / undefined values out of
 * @returns a copy of the object without any null or undefined values present
 */
export function removeEmptyValues(obj) {
  if (obj == null) {
    return obj;
  }

  return (
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, v]) => v != null)
      .reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: v === Object(v) ? removeEmptyValues(v) : v
        }),
        {}
      )
  );
}
