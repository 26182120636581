export const SIGNUP_FORM_TYPES = {
  INVITE: 'invite',
  LOGIN: 'login',
  BREEDER_SIGNUP: 'breeder-sign-up',
  SIGNUP: 'sign-up',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password'
};

export const LITTER_STATES = {
  BORN: 'born',
  EXPECTED: 'confirmed',
  PLANNED: 'new',
  ARCHIVED: 'archived',
  // NOTE: UPCOMING is not an litter status;
  // it's just an umbrella term for expected + planning
  UPCOMING: 'upcoming'
};

export const LITTER_STATE_TITLES = {
  [LITTER_STATES.BORN]: 'Newborn',
  [LITTER_STATES.EXPECTED]: 'Expected',
  [LITTER_STATES.PLANNED]: 'Planned'
};

export const LITTER_DISPLAY_STATES = {
  CURRENT: 'current',
  PAST: 'past',
  ARCHIVED: 'archived',
  DRAFT: 'draft'
};

export const LITTER_ACTIONS = {
  ANNOUNCE_BIRTH: 'announce_birth'
};

export const FACILITY_TYPE_TITLES = {
  guardian_home: 'My home and guardian homes',
  home: 'My home',
  kennel: 'Kennel',
  home_kennel: 'My home and kennel'
};

export const TRAINING_PROGRAM_TYPES = {
  SOCIALIZATION_ENRICHMENT: 'socialization_enrichment',
  TRAINING: 'training'
};

export const USER_TYPES = {
  BREEDER: 'breeder',
  BUYER: 'buyer'
};

export const ORDERED_SEARCH_TYPES = {
  BREEDERS: 'Breeders',
  SHELTERS: 'Shelters & Rescues'
};

export const ORDERED_SEARCH_TYPE_VALUES = Object.values(ORDERED_SEARCH_TYPES);

// HACK: we temporarily only wanna suggest these
export const SUGGESTED_BREED_NAMES = [
  'Australian Shepherd',
  'Beagle',
  'Bichon Frise',
  'Boston Terrier',
  'Bulldog',
  'Cavalier King Charles Spaniel',
  'Chihuahua',
  'Cocker Spaniel',
  'Dachshund',
  'French Bulldog',
  'Golden Retriever',
  'Goldendoodle',
  'Labrador Retriever',
  'Labradoodle',
  'Pomeranian',
  'Shih Tzu'
];

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video'
};

export const TRAIT_CATEGORY_TITLES = {
  allergy_friendly: 'Allergy friendly',
  coat_care: 'Coat care',
  energy_level: 'Energy level',
  health_risk: 'Health risk',
  shedding: 'Shedding'
};

export const TOAST_NOTIFICATIONS_TYPES = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success'
};

export const STRIPE_STYLES = {
  base: {
    fontFamily:
      '"GT Walsheim Pro", "avenir next", avenir, "helvetica neue", helvetica',
    fontSize: '18px',
    '::placeholder': {
      color: '#AAAAAE'
    }
  },
  invalid: {
    color: '#E5492E'
  }
};

export const BREEDING_STATUSES = {
  UPCOMING: 'Upcoming',
  ACTIVE: 'Active',
  RETIRED: 'Retired'
};

export const ACTIVE_STATES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived'
};

export const PHONE_NUMBER_TYPES = {
  MOBILE: 'mobile',
  HOME: 'home'
};

export const PHONE_NUMBER_TYPE_OPTIONS = [
  {
    title: 'Mobile',
    value: PHONE_NUMBER_TYPES.MOBILE
  },
  {
    title: 'Home',
    value: PHONE_NUMBER_TYPES.HOME
  }
];
