import normalizeUrl from 'normalize-url';
import qs from 'qs';

export function fullUrl(url, params) {
  const normalizedUrl = normalizeUrl(url, { stripWWW: false });
  const normalizedParams =
    params &&
    qs.stringify(params, {
      sort: (a, b) => a.localeCompare(b),
      arrayFormat: 'brackets'
    });
  return normalizedParams
    ? `${normalizedUrl}/?${normalizedParams}`
    : normalizedUrl;
}
