import assert from 'assert';

import { get } from 'dpl/shared/utils/object';

export function debounce(fn, ms = 0) {
  let timerId;
  function debounced(...args) {
    window.clearTimeout(timerId);
    timerId = window.setTimeout(() => {
      fn.call(this, ...args);
    }, ms);
  }

  Object.defineProperties(debounced, {
    _original: { value: fn }
  });

  return debounced;
}

export function throttle(fn, ms = 0) {
  let timerId;
  function throttled(...args) {
    if (!timerId) {
      timerId = window.setTimeout(() => {
        timerId = null;
        fn.call(this, ...args);
      }, ms);
    }
  }

  Object.defineProperties(throttled, {
    _original: { value: fn }
  });

  return throttled;
}

export function memoize(fn, keyFn) {
  assert(typeof keyFn === 'function', 'util.memoize requires a key function');

  const argsToValue = new Map();

  function memoized(...args) {
    const key = keyFn(...args);

    if (argsToValue.has(key)) {
      return argsToValue.get(key);
    }

    const ret = fn.call(this, ...args);
    argsToValue.set(key, ret);
    return ret;
  }

  Object.defineProperties(memoized, {
    _original: { value: fn }
  });

  return memoized;
}

export function isMobileUA(ua = window.navigator.userAgent || '') {
  // https://stackoverflow.com/a/24600597/3220101
  // NOTE: Newer iPads no longer identify w/ a mobile UA by default: https://developer.apple.com/forums/thread/119186.
  // Consider using breakpoint utils instead.
  return /(Mobi|Android)/i.test(ua);
}

export function isNativeAppUA() {
  return window.__DPL_NATIVE_APP_UA;
}

// https://stackoverflow.com/a/21696585/3220101
// only works when element isn't fixed; otherwise defer to getComputedStyle
export function isElementHidden(el) {
  return el.offsetParent === null;
}

export function isMobileSafari(ua = window.navigator.userAgent || '') {
  return /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
}

export const SCROLL_DIRECTIONS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};

export function scrollIntoViewIfNeeded(
  containerEl,
  targetEl,
  direction = SCROLL_DIRECTIONS.VERTICAL
) {
  const targetElRect = targetEl.getBoundingClientRect();
  const containerElRect = containerEl.getBoundingClientRect();

  if (direction === SCROLL_DIRECTIONS.VERTICAL) {
    if (targetElRect.top < containerElRect.top) {
      containerEl.scrollTop = targetEl.offsetTop - containerEl.offsetTop;
    }

    if (targetElRect.bottom > containerElRect.bottom) {
      containerEl.scrollTop =
        targetEl.offsetTop -
        containerElRect.height +
        targetElRect.height +
        containerEl.offsetTop;
    }
  }

  if (direction === SCROLL_DIRECTIONS.HORIZONTAL) {
    if (
      targetElRect.left < containerElRect.left ||
      targetElRect.right > containerElRect.right
    ) {
      containerEl.scrollLeft = targetEl.offsetLeft;
    }
  }
}

// https://stackoverflow.com/a/16270434/3220101
export function isElementInViewport(el, buffer = 0) {
  const { top, right, bottom, left } = el.getBoundingClientRect();

  return (
    bottom + buffer > 0 &&
    right + buffer > 0 &&
    left - buffer <
      (window.innerWidth || document.documentElement.clientWidth) &&
    top - buffer < (window.innerHeight || document.documentElement.clientHeight)
  );
}

// Cross-browser-compatible scrollTo
// window.scrollTo doesn't work with object options on older browsers
// This broke specifically on Galaxy Nexus 7 (Chrome 51)
// and window.scrollTo just flat-out doesn't work on mobile Safari
export const scrollTo = (() => {
  try {
    /**
     * Jest throws a NotImplemented for scrollTo which is handled by it before
     * we can catch ourselves.
     */
    window.scrollTo({});

    return ({ element = window, ...opts }) => {
      // Edge supports window.scrollTo but not element.scrollTo :'(
      if (typeof element.scrollTo !== 'function') {
        const { left, top } = opts;
        if (left) {
          element.scrollLeft = left;
        }
        if (top) {
          element.scrollTop = top;
        }
      } else {
        element.scrollTo(opts);
      }
    };
  } catch (e) {
    return ({
      element = window,
      left = element.scrollX,
      top = element.scrollY
    }) => {
      element.scrollTo(left, top);
    };
  }
})();

export function makeSequence(n) {
  return [...Array(n).keys()];
}

/**
 * Very simple, stupid omit function
 *
 * Usage:
 *   - omit({ a: 1, b: 2 }, ['a'])           // ==> { b: 2 }
 *   - omit({ a: 1, b: 2 }, k => k === 'a')  // ==> { b: 2 }
 *
 * @param { !Object } collection
 * @param { !Array|!Function } paths
 * @return { !Object }
 */
export function omit(collection, paths) {
  const tuples = Object.entries(collection);
  const retVal = {};

  tuples.forEach(([k, v]) => {
    if (
      (Array.isArray(paths) && !paths.includes(k)) ||
      (typeof paths === 'function' && !paths(k, v, retVal))
    ) {
      retVal[k] = v;
    }
  });

  return retVal;
}

/**
 * Would have just polyfilled, but Array.flat is still very experimental
 */
export const flatten = (() => {
  if (Array.prototype.flat) {
    return arr => arr.flat(Infinity);
  }

  return arr =>
    arr.reduce(
      (carry, element) =>
        carry.concat(Array.isArray(element) ? flatten(element) : element),
      []
    );
})();

export function unique(arr) {
  return [...new Set(arr)];
}

export function pick(srcObj, keys) {
  return keys.reduce(
    (destObj, key) => ({
      ...destObj,
      ...(key in srcObj && { [key]: srcObj[key] })
    }),
    {}
  );
}

export function isImageLoaded(src) {
  const image = new window.Image();
  image.src = src;

  return image.complete;
}

export function preloadImage(src, attrs = {}) {
  return new Promise((resolve, reject) => {
    const image = new window.Image();

    Object.entries(attrs).forEach(([k, v]) => image.setAttribute(k, v));
    image.src = src;

    if (isImageLoaded(src)) {
      resolve(image);
      return;
    }

    image.onerror = () => {
      // eslint-disable-next-line no-console
      console.error(`Failed to preload image with src: ${src}`);
      reject(new Error(`Failed to preload image with src: ${src}`));
    };
    image.onload = ({ target }) => {
      resolve(target);
    };
  });
}

export function findLastIndex(arr, fn) {
  const idx = [...arr].reverse().findIndex(fn);
  return idx === -1 ? idx : arr.length - idx - 1;
}

export function intersect(arrA = [], arrB = []) {
  let shorterArr = arrA;
  let longerArr = arrB;
  if (arrA.length > arrB.length) {
    shorterArr = arrB;
    longerArr = arrA;
  }

  return longerArr.filter(item => shorterArr.indexOf(item) !== -1);
}

export function difference(arrA = [], arrB = []) {
  return arrA.filter(x => !arrB.includes(x));
}

export function capitalize(str) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

/**
 * Naive impelmentation of Rails' humanize:
 * https://api.rubyonrails.org/classes/ActiveSupport/Inflector.html#method-i-humanize
 */
export function humanize(str, lowercase = false) {
  str = str.replace(/_id$/, '').replace(/_+/g, ' ');

  if (lowercase) {
    str = str.toLowerCase();
  }

  return capitalize(str);
}

function getKeyValue(item, key) {
  if (typeof key === 'function') {
    return key(item);
  }

  return get(item, key);
}

export function groupBy(arr, key) {
  assert(Array.isArray(arr), 'util.groupBy: arr must be an array');
  assert(
    typeof key === 'string' || typeof key === 'function',
    'util.groupBy: key must be a string or function'
  );

  const retVal = arr.reduce((acc, item) => {
    const keyValue = getKeyValue(item, key);

    if (!acc[keyValue]) {
      acc[keyValue] = [item];
    } else {
      acc[keyValue].push(item);
    }

    return acc;
  }, {});

  return retVal;
}

export function uniqBy(arr, key) {
  assert(Array.isArray(arr), 'util.uniqBy: arr must be an array');
  assert(
    typeof key === 'string' || typeof key === 'function',
    'util.uniqBy: key must be a string or function'
  );

  const seen = {};
  const retVal = arr.reduce((acc, item) => {
    const keyValue = getKeyValue(item, key);

    if (!seen[keyValue]) {
      seen[keyValue] = true;
      return [...acc, item];
    }

    return acc;
  }, []);

  return retVal;
}

export function isPromise(value) {
  return (
    typeof value === 'object' &&
    value !== null &&
    typeof value.then === 'function'
  );
}

/**
 * Useful when we want to programmatically open a link in a new tab while not
 * exposing the opener or the referrer
 */
export function safeOpenExternalLink(href) {
  const linkEl = document.createElement('a');
  linkEl.href = href;
  linkEl.target = '_blank';
  linkEl.rel = 'noreferrer noopener';
  linkEl.click();
}

function findMatch(originalText, length) {
  if (!originalText) {
    return '';
  }

  originalText = originalText.trim();
  // try to not break mid-word
  let match = originalText.match(new RegExp(`^(.{0,${length}})(?:\\s+|$)`));

  if (!match) {
    // no space—must break mid-word
    match = originalText.match(new RegExp(`^(.{0,${length}})`));
  }

  return match;
}

export function truncateText(originalText, length) {
  if (!originalText) {
    return '';
  }

  const [, truncatedText] = findMatch(originalText, length);

  return originalText.length === truncatedText.length
    ? originalText
    : `${truncatedText}...`;
}

export function getRandomStr(length = 5) {
  return Math.random().toString(36).substr(2, length);
}

const vowelRe = /^[aeiou]/i;
export function beginsWithVowel(str = '') {
  return vowelRe.test(str);
}

export function toggleOverlayAppContent(toggle = true) {
  const addOrRemove = toggle ? 'add' : 'remove';
  document.body.classList[addOrRemove]('no-scroll');
  window.appContent && window.appContent.classList[addOrRemove]('overlay');
}

export function chunk(arr, chunkSize) {
  return arr.reduce((chunks, item, idx) => {
    const chunkIdx = Math.floor(idx / chunkSize);
    chunks[chunkIdx] || (chunks[chunkIdx] = []);
    chunks[chunkIdx].push(item);
    return chunks;
  }, []);
}

export function shuffle(arr) {
  const s = [...arr];
  let idx = s.length;

  while (idx) {
    const randIdx = Math.floor(Math.random() * idx);
    idx--;
    [s[idx], s[randIdx]] = [s[randIdx], s[idx]];
  }

  return s;
}

export function sample(arr, count) {
  return shuffle(arr).slice(0, count);
}

export const copyToClipboard = (() => {
  if (window.navigator.clipboard) {
    return window.navigator.clipboard.writeText.bind(
      window.navigator.clipboard
    );
  }

  if (window.__clipboardPolyfill) {
    return window.__clipboardPolyfill.writeText.bind(
      window.__clipboardPolyfill
    );
  }

  return () => Promise.reject(new Error('No clipboard support'));
})();

export function noop() {}

// From MDN:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function repositionArrayItem(currentIdx, newIdx, arr) {
  if (arr[currentIdx]) {
    arr.splice(newIdx, 0, arr.splice(currentIdx, 1)[0]);
  }
  return arr;
}

// Replace diacritics in strings with their Basic Latin equivalents, e.g.:
//   - "héllô" --> "hello"
//   - "Blårf La Bùttée" --> "Blarf La Buttee"
//
// https://github.com/krisk/Fuse/issues/415
// https://en.wikipedia.org/wiki/Combining_Diacritical_Marks#Character_table
//
// NOTE: This does NOT handle ligatures (æ, œ, ß) since they're distinct glyphs
export function normalizeDiacritics(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
