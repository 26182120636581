// eslint-disable-next-line no-restricted-imports
import { INSIGHTS_TABS } from 'dpl/breeder_dashboard/utils/constants';

import { fullUrl } from '../fetching/utils/fullUrl';

export const ROOT_HOST = CONFIG.host;
export const BREEDER_DASHBOARD_ROOT = '/breeder/dashboard';
export const BREEDER_DASHBOARD_PATH = `${BREEDER_DASHBOARD_ROOT}/overview`;
export const BREEDER_MESSAGES_PATH = `${BREEDER_DASHBOARD_ROOT}/messages`;
export const BREEDER_ACTIVE_MESSAGES_PATH = `${BREEDER_MESSAGES_PATH}/active`;
export const BREEDER_ARCHIVED_MESSAGES_PATH = `${BREEDER_MESSAGES_PATH}/archived`;
export const BREEDER_APPLICANT_MESSAGES_PATH = `${BREEDER_MESSAGES_PATH}/applicants`;
export const BREEDER_WAITLIST_MESSAGES_PATH = `${BREEDER_MESSAGES_PATH}/waitlist`;
export const BREEDER_OWNER_MESSAGES_PATH = `${BREEDER_MESSAGES_PATH}/owners`;
export const BREEDER_LITTERS_PATH = `${BREEDER_DASHBOARD_ROOT}/litters`;
export const BREEDER_BORN_LITTERS_PATH = `${BREEDER_DASHBOARD_ROOT}/litters/current`;
export const BREEDER_PAST_LITTERS_PATH = `${BREEDER_DASHBOARD_ROOT}/litters/past`;
export const BREEDER_ARCHIVED_LITTERS_PATH = `${BREEDER_DASHBOARD_ROOT}/litters/archived`;
export const BREEDER_DRAFT_LITTERS_PATH = `${BREEDER_DASHBOARD_ROOT}/litters/draft`;
export const BREEDER_SIRES_DAMS_PATH = `${BREEDER_DASHBOARD_ROOT}/parents`;
export const BREEDER_UPDATES_PATH = `${BREEDER_DASHBOARD_ROOT}/updates`;
export const BREEDER_ACTIVE_PARENTS_PATH = `${BREEDER_SIRES_DAMS_PATH}/all`;
export const BREEDER_ACTIVE_SIRES_PATH = `${BREEDER_SIRES_DAMS_PATH}/sires`;
export const BREEDER_ACTIVE_DAMS_PATH = `${BREEDER_SIRES_DAMS_PATH}/dams`;
export const BREEDER_ARCHIVED_PARENTS_PATH = `${BREEDER_SIRES_DAMS_PATH}/archived`;
export const BREEDER_PAYMENTS_PATH = `${BREEDER_DASHBOARD_ROOT}/payments`;
export const BREEDER_INSIGHTS_PATH = `${BREEDER_DASHBOARD_ROOT}/insights`;
export const BREEDER_INSIGHTS_OPPORTUNITIES_PATH = `${BREEDER_INSIGHTS_PATH}/${INSIGHTS_TABS.OPPORTUNITIES}`;
export const BREEDER_INSIGHTS_STATS_PATH = `${BREEDER_INSIGHTS_PATH}/${INSIGHTS_TABS.ANALYTICS}`;
export const BREEDER_INSIGHTS_STORIES_PATH = `${BREEDER_INSIGHTS_PATH}/${INSIGHTS_TABS.OWNER_STORIES}`;
export const BREEDER_TESTIMONIAL = `${BREEDER_DASHBOARD_ROOT}/owner-stories`;

export const BREEDER_PAST_FAMILIES_PATH = `${BREEDER_DASHBOARD_ROOT}/past-families`;
export const BREEDER_ACTIVE_PAST_FAMILIES_PATH = `${BREEDER_DASHBOARD_ROOT}/past-families/active`;
export const BREEDER_ARCHIVED_PAST_FAMILIES_PATH = `${BREEDER_DASHBOARD_ROOT}/past-families/archived`;
export const BREEDER_BASE_APPLICATIONS_PATH = `${BREEDER_DASHBOARD_ROOT}/applications`;
export const BREEDER_APPLICATIONS_PATH = `${BREEDER_DASHBOARD_ROOT}/applications/active`;
export const BREEDER_ARCHIVED_APPLICATIONS_PATH = `${BREEDER_DASHBOARD_ROOT}/applications/archived`;
export const BREEDER_BASE_WAITLIST_PATH = `${BREEDER_DASHBOARD_ROOT}/waitlist`;
export const BREEDER_WAITLIST_PATH = `${BREEDER_DASHBOARD_ROOT}/waitlist/active`;
export const BREEDER_ARCHIVED_WAITLIST_PATH = `${BREEDER_DASHBOARD_ROOT}/waitlist/archived`;

export const BREEDER_ONBOARDING_PATH = `${BREEDER_DASHBOARD_ROOT}/onboarding`;
export const BREEDER_EDIT_PROFILE_PATH = '/breeder/edit/about';
export const BREEDER_EDIT_GALLERY_PATH = '/breeder/edit/gallery';
export const BREEDER_EDIT_ABOUT_PATH = '/breeder/edit/about';
export const BREEDER_EDIT_POLICIES_PATH = '/breeder/edit/policies';
export const BREEDER_EDIT_TRANPORTATION_PATH = '/breeder/edit/transportation';
export const BREEDER_EDIT_HEALTH_PATH = '/breeder/edit/health';
export const BREEDER_EDIT_APPLICATION_PATH = '/breeder/edit/application';
export const BREEDER_SETTINGS_PATH = '/breeder/settings';
export const BREEDER_SETTINGS_PAYMENT_PATH = '/breeder/settings/payment';
export const BREEDER_SETTINGS_ACCOUNT_PATH = '/breeder/settings/account';
export const BREEDER_SETTINGS_CONTRACT_PATH = '/breeder/settings/contracts';
export const BREEDER_DOWNLOADS_PATH = '/breeder/downloads';
export const BREEDER_REFERRAL_LINKS_PATH = '/breeder/referral-links';
export const SIGNOUT_PATH = '/api/users/sign_out';
export const ME_PATH = '/api/me.json';
export const UPDATE_ME_PATH = '/api/users.json';
export const HELP_PATH = '/help';
export const HOME_PATH = '/';
export const LOGIN_PATH = '/auth/login';
export const ABOUT_US_PATH = '/about-us';
export const CAREERS_PATH = '/careers';
export const JOIN_AS_BREEDER_PATH = '/join-as-breeder';
export const JOIN_AS_SHELTER_PATH = '/join-as-shelter-or-rescue';
export const MISSION_PATH = '/our-mission';
export const JOINING_FAQS_PATH = '/joining-good-dog-faq';
export const PAYMENT_LANDING_PATH = '/payment-on-good-dog';
export const LETTER_TO_RESPONSIBLE_BREEDERS_PATH =
  '/letter-to-responsible-breeders';
export const GOOD_DOG_POD_PATH = '/good-dog-pod';
export const GOOD_DOG_STORE_URL = 'https://shop.gooddog.com';
export const NONDISCRIMINATION_POLICY_PATH =
  '/inclusion-and-nondiscrimination-policy';
export const PUPPY_TRAINING_PROGRAM = '/puppy-training-program';
export const PUPPY_TRAINING_PROGRAM_LANDING = '/introducing-puppy-training';

// Learning Center paths
export const LEARNING_CENTER_PATH = '/learning-center';
export const DELIVERY_PATH = `${LEARNING_CENTER_PATH}/transportation`;
export const LEARNING_CENTER_SHELTER_PATH = `${LEARNING_CENTER_PATH}/how-the-adoption-process-works`;
export const LEARNING_CENTER_PRICE_PATH = `${LEARNING_CENTER_PATH}/cost-of-a-puppy-from-a-responsible-breeder`;
export const LEARNING_CENTER_BREEDER_PROCESS_PATH = `${LEARNING_CENTER_PATH}/how-the-process-works-of-becoming-a-good-breeder`;
export const LEARNING_CENTER_BREED_TYPES = `${LEARNING_CENTER_PATH}/differences-between-purebreds-and-crossbreds`;
export const LEARNING_CENTER_HOW_TO_PREPARE = `${LEARNING_CENTER_PATH}/how-to-prepare-your-home-for-a-new-puppy`;
export const LEARNING_CENTER_WHAT_TO_BRING = `${LEARNING_CENTER_PATH}/what-to-bring-when-you-pick-your-puppy-up`;
export const LEARNING_CENTER_FIRST_MONTH = `${LEARNING_CENTER_PATH}/your-puppys-first-month-at-home`;
export const LEARNING_CENTER_CHOOSE_A_BREEDER = `${LEARNING_CENTER_PATH}/how-to-choose-a-breeder`;
export const LEARNING_CENTER_WORKING_WITH_BREEDER = `${LEARNING_CENTER_PATH}/working-with-a-breeder`;
export const LEARNING_CENTER_HAPPY_HOME_PATH = `${LEARNING_CENTER_PATH}/tips-for-a-safe-happy-trip-home`;
export const LEARNING_CENTER_FLY_SAFELY_PATH = `${LEARNING_CENTER_PATH}/tips-fly-safe-dog`;
export const LEARNING_CENTER_FLIGHT_NANNY_PATH = `${LEARNING_CENTER_PATH}/transportation-flight-nanny`;

// Good Breeder Center paths
export const BREEDER_CENTER_PATH = '/good-breeder-center';
export const WATERMARK_PROTECT_SENSITIVE_INFO_PATH = `${BREEDER_CENTER_PATH}/watermark-and-protect-sensitive-information`;
export const E_CONTRACTS_GUIDE_PATH = `${BREEDER_CENTER_PATH}/e-contracts`;
export const HOW_TO_PLACE_PUPPIES_PATH = `${BREEDER_CENTER_PATH}/how-to-place-your-puppies`;
export const ON_PLATFORM_POLICY_PATH = `${BREEDER_CENTER_PATH}/on-platform-policy`;
export const RECOMMENDED_TRANSPORTERS_PATH = `${BREEDER_CENTER_PATH}/good-breeder-recommended-transporters`;
export const HOW_TO_TAKE_PHOTOS_PATH = `${BREEDER_CENTER_PATH}/episode-011-how-to-take-great-photos-of-your-dogs`;
export const APPLICATION_PROCESS_ANNOUNCEMENT_PATH = `${BREEDER_CENTER_PATH}/announcing-the-return-of-the-application-process`;
export const BREEDER_DISCOUNTS_PATH = `${BREEDER_CENTER_PATH}/good-breeder-discounts`;
export const HOW_TO_TAKE_PUPPY_PHOTOS_PATH = `${BREEDER_CENTER_PATH}/how-to-take-puppy-photos`;
export const PAYMENT_FAQS_PATH = `${BREEDER_CENTER_PATH}/payment-faqs`;
export const PAYMENT_INFORMATION_FOR_BUYERS = `${BREEDER_CENTER_PATH}/payment-information-for-buyers`;
export const BREEDER_FEE_FAQS_PATH = `${BREEDER_CENTER_PATH}/good-dogs-path-forward-new-pricing-structure#frequently-asked-questions`;
export const BREEDER_FEE_PATH = `${BREEDER_CENTER_PATH}/good-dogs-path-forward-new-pricing-structure`;

export const LEGAL_HUB_PATH = '/legal-hub';
export const BREEDER_QUALITY_PATH = '/what-is-a-good-breeder';
export const SHELTER_QUALITY_PATH = '/what-are-good-shelters-rescues';
export const STANDARDS_PATH = '/standards-and-screening';
export const HEALTH_TESTING_GUIDE_PATH =
  '/guide-to-good-dogs-health-testing-levels';
export const BREED_HEALTH_TESTING_GUIDE_PATH = '/health-testing-guide';
export const TOS_PATH = '/terms-of-service';
export const PRIVACY_PATH = '/privacy-policy';
export const BREED_DIRECTORY_PATH = '/directory/breeds';
export const PUPPY_DIRECTORY_PATH = '/directory/puppies';
export const BREEDER_DIRECTORY_PATH = '/directory/breeders';

export const COE_BREEDER_PATH = '/breeder-code-of-ethics';
export const COE_PARENT_PATH = '/dog-parent-code-of-ethics';
export const COE_SHELTER_PATH = '/shelter-rescue-code-of-ethics';

export const BUYER_BASE_PATH = '/account';
export const BUYER_MESSAGES_PATH = `${BUYER_BASE_PATH}/messages`;
export const BUYER_ACTIVE_MESSAGES_PATH = `${BUYER_MESSAGES_PATH}/active`;
export const BUYER_APPLICATIONS_PATH = `${BUYER_BASE_PATH}/applications`;
export const BUYER_PAYMENTS_PATH = `${BUYER_BASE_PATH}/payments`;
export const BUYER_SETTINGS_PATH = `${BUYER_BASE_PATH}/settings`;
export const BUYER_FAVORITES_PATH = `${BUYER_BASE_PATH}/favorites`;
export const BUYER_FAVORITE_BREEDERS_PATH = `${BUYER_FAVORITES_PATH}/breeders`;
export const BUYER_FAVORITE_PUPPIES_PATH = `${BUYER_FAVORITES_PATH}/puppies`;

// Help Center paths
export const HELP_CENTER_PATH = '/help-center';
export const HELP_CENTER_BUYERS_PATH = `${HELP_CENTER_PATH}/owners`;
export const HELP_CENTER_BREEDERS_PATH = `${HELP_CENTER_PATH}/breeders`;
export const HELP_CENTER_SHELTERS_PATH = `${HELP_CENTER_PATH}/shelters-rescues`;
export const HELP_CENTER_ARTICLE_BUYER_PAYMENTS_PATH = `${HELP_CENTER_BUYERS_PATH}/payments`;
export const HELP_CENTER_ADULT_DOGS_PATH = `${HELP_CENTER_BUYERS_PATH}?a=Adult-dogs---id--RXcD_PVNQfOChXfJC263KQ`;
export const HELP_CENTER_LISTING_ADULT_DOGS_PATH = `${HELP_CENTER_PATH}?a=Listing-adult-dogs---id--9uHeD3TGQJ6J9kmx69XUTQ`;
export const HELP_CENTER_BUYER_VERIFICATION_PATH = `${HELP_CENTER_PATH}?a=How-Buyer-Verification-Works-on-Good-Dog---id--8fEh9wp0Q62-qTw_eeuYoA`;

export const INDEXED_REGISTRATION_PATHS = [
  '/breeder-sign-up/general-info',
  '/breeder-sign-up/health',
  '/breeder-sign-up/process',
  '/breeder-sign-up/video-visit'
];

export const FOOTER_PATHS = {
  TERMS: TOS_PATH,
  PRIVACY: PRIVACY_PATH,
  BREEDER_ETHICS: COE_BREEDER_PATH,
  PARENT_ETHICS: COE_PARENT_PATH
};

// See SIGNUP_FORM_TYPES in constants/shared.js
export const AUTH_PATHS = {
  INVITE: '/auth/invite',
  LOGIN: '/auth/login',
  BREEDER_SIGNUP: '/auth/breeder-sign-up',
  SIGNUP: '/auth/sign-up',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password'
};

export const CHARITY_NAVIGATOR_GENERAL_URL =
  'https://www.charitynavigator.org/index.cfm?bay=content.view&cpid=5593';

export const EXPLORE_BREEDS_PATH = '/explore';
export const EXPLORE_SHELTERS_PATH = '/shelters';

export const PHONE_CALL_PATH = '/phone_calls';

export const SAMPLE_CONTRACTS_PATH = `${LEGAL_HUB_PATH}/sample-contracts`;

export const PAYMENT_PROTECTION_POLICY_PATH = `${HELP_CENTER_BUYERS_PATH}?a=Good-Dog-Buyer-Payment-Protection-Policy---id--K4NN3ZKDSOyO3buACI2ebw`;
export const HELP_CENTER_SERVICE_FEE_PATH = `${HELP_CENTER_BUYERS_PATH}?a=What-is-the-Good-Dog-Service-and-Protection-Fee---id--PhBVPvcYQ26uqUDvE3HpRw`;
export const HELP_CENTER_TRANSPORTATION_PATH = `${HELP_CENTER_BUYERS_PATH}?a=Transportation-options---id--U7smS5gIQOyXMUszwRQnnw`;
export const HELP_CENTER_ON_PLATFORM_PAYMENT_POLICY_PATH = `${HELP_CENTER_BUYERS_PATH}?a=On-Platform-Payment-Policy---id--CK1m0GVSSCeEH3g4iygaSg`;

export const GOOD_DOG_BREEDER_GUARANTEE_PATH = `${HELP_CENTER_BREEDERS_PATH}?a=The-Good-Dog-Breeder-Guarantee---id--ledhWl52St-bReXQ2OxpeA`;
export const HELP_CENTER_REFUNDS_PATH = `${HELP_CENTER_BREEDERS_PATH}?a=Refunds---id--9sFEQhwkQF62u-xQpNSnKg`;
export const HELP_MINIMUM_PRICE_PATH = `${HELP_CENTER_BREEDERS_PATH}?a=Minimum-price---id--0gV2hnm5SLmibAGVP8UyVw`;
export const HELP_CENTER_OFF_PLATFORM_PAYMENTS = `${HELP_CENTER_BREEDERS_PATH}/?a=Payments-for-non-Good-Dog-buyers---id--ESQpm3dSTcqzW0nsVWADuA`;
export const PAYMENT_REQUEST_SAMPLE_IMG_PATH = '/payment_request_sample.png';

export const INTERNAL_TOOLS_PATH = '/internal_tools';
export const INTERNAL_TOOLS_BREEDER_ACCOUNT_INDEX_PATH = `${INTERNAL_TOOLS_PATH}/breeder_accounts`;
export const INTERNAL_TOOLS_TESTIMONIAL_PATH = `${INTERNAL_TOOLS_PATH}/testimonials`;

export const CHARGEBACKS_AND_FEES_PATH = `${TOS_PATH}#5-use-of-the-payment-services-and-protection-from-fraudulent-chargebacks`;

export const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${CONFIG.googleMapsApiKey}&libraries=places&callback=noop`;

export function paymentRequestPath({ id, params = {} }) {
  const base = `/payment_requests/${id}/stripe_charge/new`;
  return fullUrl(base, params);
}

// Breed search results paths
export const GOLDEN_RETRIEVER_PATH = '/golden-retriever/puppies';
export const HAVANESE_PATH = '/havanese/puppies';

// App Store
export const BREEDER_APP_STORE_URL =
  'https://www.gooddog.com/app_store/breeder';
export const BUYER_APP_STORE_URL = 'https://www.gooddog.com/app_store/buyer';

// Breed overview paths
export const CAROLINE_COILE_URL = 'http://www.carolinecoile.com/about.html';

// Insights How To paths
export const INSIGHTS_HOW_TO_IMPROVE_STORIES = `${BREEDER_INSIGHTS_PATH}/improve/testimonials`;

export const REVIEWS_PAGE_PATH = '/reviews';
