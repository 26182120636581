import { get } from 'dpl/shared/utils/object';

export default function isFeatureEnabled(name) {
  const isValidName =
    name && Object.hasOwnProperty.call(window.__DPL_FEATURES ?? {}, name);

  if (!isValidName) {
    if (CONFIG.isDevelopment) {
      throw new Error(`[${name}] is not a valid feature flag.`);
    } else {
      return false;
    }
  }

  return get(window.__DPL_FEATURES, name, false);
}
