import { LITTER_DISPLAY_STATES } from 'dpl/shared/constants/shared';
import { WATERMARK_PROTECT_SENSITIVE_INFO_PATH } from 'dpl/shared/constants/urls';

export const HEALTH_TESTING_LEVELS_MODAL_SECTIONS = [
  {
    icon: 'shield-health',
    iconSize: '32px',
    title: 'Add health testing',
    bodyText: 'Easily select which tests you perform for each sire and dam.'
  },
  {
    icon: 'notebook',
    iconSize: '32px',
    title: 'Provide documentation',
    bodyText:
      'Click attach files & links, then choose either to upload files or add a link.'
  },
  {
    icon: 'checkmark',
    iconSize: '32px',
    title: 'Display health tests',
    bodyText:
      'Make sure your health toggle is set to live to display the results on your profile.',
    bodyLink: WATERMARK_PROTECT_SENSITIVE_INFO_PATH,
    bodyLinkText:
      'How to watermark and protect sensitive information in documents & photos'
  },
  {
    icon: 'health-badge-heart',
    iconSize: '24px',
    title: 'Receive your health test level',
    bodyText:
      'It will take up to 3 days for your health tests and badge to be updated on your profile.'
  }
];

export const EMPTY_LITTER_STATES = {
  [LITTER_DISPLAY_STATES.CURRENT]: 'currentLitters',
  [LITTER_DISPLAY_STATES.PAST]: 'pastLitters',
  [LITTER_DISPLAY_STATES.ARCHIVED]: 'archivedLitters'
};

export const LITTER_MEDIA_CATEGORIES = {
  CURRENT_LITTER: 'current-litter',
  PAST_LITTER: 'past-litter'
};

export const PERFORMANCE_CHART_TYPES = {
  PROGRESS_BAR: 'progress_bar',
  DOT_PLOT: 'dot_plot'
};

export const STATS_TRAFFIC_TYPES = {
  GOOD_DOG_DIRECT: 'good-dog-direct',
  GOOD_DOG_ADS: 'good-dog-ads',
  GOOD_DOG_SEO: 'good-dog-seo',
  GOOD_DOG_BREEDER_PROFILE: 'good-dog-breeder-profile',
  BREEDER_SOCIAL_PAGES: 'breeder-social-pages'
};

export const INSIGHTS_TABS = {
  OPPORTUNITIES: 'opportunities',
  ANALYTICS: 'analytics',
  OWNER_STORIES: 'owner-stories'
};

export const PERFORMANCE_FACTOR_TYPES = {
  HEALTH_TESTS: 'health-tests',
  LISTINGS_COMPLETENESS: 'listings-completeness',
  PLACEMENT_RATE: 'placement-rate',
  TESTIMONIALS: 'testimonials'
};

export const INSIGHTS_ANNOUNCEMENT_BANNER = {
  TITLE: 'Introducing Insights',
  DESCRIPTION:
    'Track your performance, increase your visibility, and connect with more quality buyers using your personalized Insights Dashboard.',
  SHORT_DESCRIPTION: 'Track your performance and reach more quality buyers.',
  PRIMARY_CTA_LABEL: 'Go to Insights',
  SECONDARY_CTA_LABEL: 'Learn more'
};
