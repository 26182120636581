import invariant from 'invariant';

import isFeatureEnabled from 'dpl/util/isFeatureEnabled';
import {
  EVENT_CATEGORIES,
  EVENT_ACTION_IDS,
  ANALYTICS_PROVIDERS,
  ANALYTICS_EVENTS,
  ANALYTICS_PLATFORM
} from 'dpl/constants/analytics';
import { FEATURES } from 'dpl/shared/constants/features';
import { getAnalyticsOrigin } from 'dpl/shared/utils/getAnalyticsOrigin';
import { getUserTimeZone } from 'dpl/shared/utils/date';
import { removeEmptyValues } from 'dpl/shared/utils/object';

const timezone = getUserTimeZone();

export function sendGAEvent(eventAction, opts = {}) {
  return new Promise(resolve => {
    if (typeof window.gtag !== 'function') {
      resolve();
      return;
    }

    setTimeout(resolve, 1000);

    window.gtag('event', eventAction, opts);
  });
}

export function sendPageview(opts, rudderOpts) {
  if (typeof window.gtag === 'function') {
    window.gtag('config', window.__DPL_GA_TRACKING_ID, {
      ...window.__DPL_GA_OPTIONS,
      ...opts
    });
  }

  if ('rudderanalytics' in window) {
    const { category, name } = rudderOpts || {};
    window.rudderanalytics.page(category, name);
  }
}

export function sendFBEvent(eventName) {
  if (typeof window.fbq === 'function') {
    window.fbq('track', eventName);
  }
}

export function sendBingEvent(eventAction, opts = {}) {
  if ('uetq' in window) {
    window.uetq.push('event', eventAction, opts);
  }
}

export function sendRudderstackEvent(event, properties = {}) {
  invariant(
    Object.values(ANALYTICS_EVENTS).includes(event),
    `Event must exist in ANALYTICS_EVENTS constant. ${event}`
  );

  const defaultProps = window?.__GD_ANALYTICS_TRACK_DEFAULT_PROPS || {};
  const analyticsOrigin = {
    ...getAnalyticsOrigin(),
    ...removeEmptyValues({
      origin_view: properties.origin_view || null,
      origin_view_feature: properties.origin_view_feature || null,
      origin_view_feature_location:
        properties.origin_view_feature_location || null
    })
  };

  if ('rudderanalytics' in window) {
    window.rudderanalytics.track(event, {
      ...properties,
      ...defaultProps,
      ...analyticsOrigin,
      app_version: CONFIG.appVersion,
      platform: ANALYTICS_PLATFORM,
      timezone
    });
  }
}

export function sendRudderstackGroupEvent(groupId, traits = {}) {
  if ('rudderanalytics' in window) {
    window.rudderanalytics.group(groupId, {
      ...traits,
      app_version: CONFIG.appVersion,
      platform: ANALYTICS_PLATFORM,
      timezone
    });
  }
}

export function sendAnalyticsEvent(event, properties = {}) {
  invariant(
    Object.values(ANALYTICS_EVENTS).includes(event),
    `Event must exist in ANALYTICS_EVENTS constant. ${event}`
  );

  if ('analytics' in window) {
    window.analytics.track(event, {
      ...properties,
      app_version: CONFIG.appVersion,
      platform: ANALYTICS_PLATFORM,
      timezone
    });
  }

  sendRudderstackEvent(event, properties);
}

// TODO: just send GA events directly now that we no longer use ahoy
export function sendAhoyAndGAEvents(eventAction, opts = {}) {
  sendGAEvent(eventAction, opts);
}

export function trackEvent(
  eventCategory,
  eventAction,
  opts,
  providers = [ANALYTICS_PROVIDERS.GA]
) {
  invariant(
    Object.values(EVENT_CATEGORIES).includes(eventCategory),
    'Event category must exist in EVENT_CATEGORIES constant.'
  );

  return fn =>
    function eventTrackingFn(e, ...args) {
      if (!eventAction) {
        eventAction = e && typeof e.type === 'string' ? e.type : 'click';
      }

      const finalOpts = {
        event_category: eventCategory,
        ...opts
      };

      if (providers.includes(ANALYTICS_PROVIDERS.GA)) {
        sendGAEvent(eventAction, finalOpts);
      }

      if (providers.includes(ANALYTICS_PROVIDERS.BING)) {
        sendBingEvent(eventAction, finalOpts);
      }

      if (providers.includes(ANALYTICS_PROVIDERS.FB)) {
        sendFBEvent(eventCategory);
      }

      return fn.call(this, e, ...args);
    };
}

export function trackConversion(actionId) {
  invariant(
    Object.values(EVENT_ACTION_IDS).includes(actionId),
    'Event actionId must exist in EVENT_ACTION_IDS constant.'
  );

  return fn =>
    function conversionTrackingFn(...args) {
      sendGAEvent('conversion', { send_to: actionId });

      return fn.call(this, ...args);
    };
}

export function trackConversionV2(actionId) {
  invariant(
    Object.values(EVENT_ACTION_IDS).includes(actionId),
    'Event actionId must exist in EVENT_ACTION_IDS constant.'
  );

  sendGAEvent('conversion', { send_to: actionId });
}

export function sendAnalyticsIdentify({ userId, traits }) {
  if (!userId) {
    return;
  }

  // do not call identify when its an admin user impersonating
  // someone in production
  if (window.__GD_CURRENT_USER_ID !== '' && window.__GD_ENV_PROD === 'true') {
    return;
  }

  if ('rudderanalytics' in window) {
    window.rudderanalytics.identify(`${userId}`, traits);
  }
}

export function analyticsReset() {
  if ('analytics' in window) {
    window.analytics.reset();
  }
}

export function collectTrackedExperiments(features = FEATURES) {
  const regex = /^EXP(ERIMENT)?_/;

  return Object.keys(features)
    .filter(feature => regex.test(feature))
    .map(experimentalFeature => {
      return {
        experiment_name: experimentalFeature.replace(regex, ''),
        treatment: isFeatureEnabled(features[experimentalFeature])
          ? 'test'
          : 'control'
      };
    });
}

export function trackPageView({ name, category }) {
  sendPageview(
    {
      page_title: document.title,
      page_path: `${window.location.pathname}${window.location.search}`,
      page_location: window.location.href
    },
    { name, category }
  );
}
